.card {
  flex: 0 1 100%;
  min-width: 220px;
  border: 1px solid #dfdfff;
  border-radius: 15px;
  box-shadow: 3px 3px 3px -3px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  margin: 5px 0 15px 0;
  background: #fff;
  height: 290px;
}

.card h3 {
  font-size: 18px;
  font-weight: 500;
  background-color: #9ac1c1;
  color: #444;
  padding: 10px 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #dfdfff;
}

.card .card-icon {
  display: inline-block;
  margin-right: 10px;
}

.card-body {
  margin: 15px 0 15px 15px;
  padding-right: 15px;
  overflow-y: auto;
  height: 230px;
}

.card-body ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-body li {
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: #444;
  padding-bottom: 15px;
}

.card-body strong {
  font-size: 16px;
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}

.card-item {
  margin-bottom: 10px;
  word-break: break-word;
}

.card-item a {
  text-decoration: none;
  color: #444 !important;
}

@media (min-width: 578px) {
  .card {
    flex: 0 1 calc(50% - 10px);
  }
}
