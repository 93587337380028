.navbar .navbar-closed {
  height: 33px;
}

.navbar header {
  background-color: #475e74;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
}

.navbar header h1 {
  margin: 5px auto;
}

.navbar header h1 a {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  color: #f8f8ff;
  text-decoration: none;
  padding: 0 15px;
  font-weight: 300;
  font-size: 30px;
}

.navbar header h1 strong {
  font-weight: 500;
}

.navbar nav {
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  background: #9ac1c1;
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.2);
}

.navbar nav ul {
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-align: center;
  padding: 0;
  margin: 0px auto;
}

.navbar nav ul li a {
  color: #444;
  text-decoration: none;
  display: block;
  outline: none !important;
  padding: 5px 15px;
}
.navbar nav ul li a:hover,
.navbar nav ul li button:hover {
  text-decoration: none;
  background-color: #475e74;
  color: #fff;
}

.navbar nav ul li button {
  display: block;
  padding: 5px 15px;
  border: none;
  background: none;
  font-size: 20px;
  color: #475e74;
  cursor: pointer;
  outline: none !important;
  margin: 0;
  text-align: center;
  width: 100%;
}

.navbar button:focus,
.navbar button:active {
  outline: none !important;
  border: none !important;
}

.navbar nav ul li a:hover,
.navbar nav ul li button:hover {
  text-decoration: none;
}

.navbar nav ul li {
  display: none;
}

.navbar nav .navbar-open li {
  display: block;
}

.navbar nav ul li.navbar-toggle {
  display: block;
}

.navbar .navbar-open {
  height: 199px;
}

@media (min-width: 578px) {
  .navbar nav ul li {
    display: inline-block;
  }

  .navbar nav ul li.navbar-toggle {
    display: none;
  }

  .navbar .navbar-open {
    height: 33px;
  }

  .navbar nav .navbar-open li {
    display: inline-block;
  }
}
