.cb-img {
  width: 100%;
  margin: 12px 0;
}

.cb-logo {
  width: 200px;
  margin: 12px auto;
  display: block;
}
