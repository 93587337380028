.footer {
  background-color: #9ac1c1;
  color: #475e74;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer a {
  color: #475e74;
  text-decoration: none;
}

.footer ul {
  list-style: none;
  text-align: center;
  padding: 15px 0;
  margin: 0;
}

.footer li {
  display: inline-block;
  padding: 15px;
}
