* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ff;
  color: #444;
}

#main {
  min-height: calc(100vh);
  position: relative;
  padding-top: 90px;
  padding-bottom: 100px;
}

a,
a:focus,
a:active {
  outline: none !important;
}

strong {
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
li {
  font-family: "Muli", sans-serif;
  font-size: 18px;
}

p {
  line-height: 28px;
  margin-top: 0;
}

.container {
  padding: 0 15px;
  margin: auto;
  margin-top: 15px;
  max-width: 768px;
}

.cols {
  width: 100%;
  /* align-items: flex-start; */
}

.col {
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

@media (min-width: 578px) {
  .col {
    width: 50%;
  }
}

.ml-1 {
  margin-left: 6px;
}

.ml-2 {
  margin-left: 12px;
}

.mt-2 {
  margin-top: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  margin: 0;
  margin: 0;
}

hr {
  border: none;
  text-align: center;
  margin: 15px 0;
  clear: both;
}

hr:after {
  content: "●";
  font-size: 30px;
  font-weight: 500px;
  color: #475e74;
  font-family: "Muli", sans-serif;
}

a {
  color: #475e74;
}
a:focus {
  outline: none;
}

h2 {
  margin-top: 12px;
  margin-bottom: 6px;
}

h3 {
  margin-bottom: 6px;
}

.text-center {
  text-align: center;
}

.anchor:before {
  content: "";
  display: block;
  position: relative;
  width: 0;
  height: 115px;
  margin-top: -115px;
}

button:focus,
button:active {
  outline: none !important;
}

button.text {
  background: none;
  border: none;
  padding: 0;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #444;
  cursor: pointer;
}

button.link {
  background: none;
  border: none;
  padding: 0;
  font-family: "Muli", sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #475e74;
  text-decoration: underline;
  cursor: pointer;
}

.d-none {
  display: none;
}

@media (min-width: 400px) {
  .d-md-inline {
    display: inline;
  }
  .d-md-none {
    display: none;
  }
}

.airtable-embed {
  margin-top: 30px;
}

img {
  max-width: 100%;
}

.hr {
  padding-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dedede;
  clear: both;
}
